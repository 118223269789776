import {
  AutocompleteArrayInput,
  BooleanInput,
  DateTimeInput,
  NumberInput,
  ReferenceArrayInput,
  maxValue,
  minValue,
  required,
  useGetIdentity,
  useGetList,
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate,
} from 'react-admin';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import {
  GenderType,
  User,
  UserRole,
  User as UserType,
  Vehicle,
} from '../../@generated/schemas';
import { ReactNode, useEffect } from 'react';
import { omit, set, uniqBy } from 'lodash';
import {
  useGenerateUserOnboardingStripeLinkMutation,
  useResendEmailVerificationLinkMutation,
} from '../../@generated/hooks';

import { AssignedSharedVehicleGroupList } from '../sharedVehicleGroup/assignedList';
import CheckIcon from '@mui/icons-material/Check';
import CompanyInput from '../../common/CompanyInput';
import DateInput from '../../common/DateInput';
import { LoadingElement } from '../../App';
import SelectInput from '../../common/SelectInput';
import { TABS } from './tabs';
import { TabPanel } from '@mui/lab';
import TextInput from '../../common/TextInput';
import { convertEnumFilterItems } from '../../libs/convertEnumFilterItems';
import cookie from 'js-cookie';
import countriesList from '../../libs/countries.json';
import dayjs from 'dayjs';
import getStoragePath from '../../libs/getStoragePath';
import styled from 'styled-components';
import { url } from 'src';
import { useFormContext } from 'react-hook-form';

const StyledBorder = styled.div`
  border: 1px solid #e0e0e3;
  padding: 12px;
  padding-top: 20px;
  position: relative;
  border-radius: 4px;
`;

const StyledTypography = styled(Typography)`
  position: absolute;
  top: -16px;
  background: #fff;
  padding: 0 10px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LevelLabel = styled.div`
  border-radius: 11px;
  width: 100%;
  margin-right: 12px;
  margin-bottom: 8px;
  padding: 12px 12px;
`;

const LevelLabelItem = ({
  title,
  confirmed = false,
  additionalInfo,
}: {
  title: string;
  confirmed?: boolean | null;
  additionalInfo?: ReactNode | string | null;
}) => {
  return (
    <LevelLabel>
      <FlexRow>
        <div>{title}</div>
        {confirmed && <CheckIcon style={{ marginLeft: 12 }} width={14} />}
      </FlexRow>
      {!!confirmed ? (
        <i style={{ fontSize: 12 }}>
          {typeof confirmed !== 'boolean'
            ? `(${dayjs(confirmed).format('DD.MM. YYYY HH:mm')})`
            : ''}
        </i>
      ) : null}
      {additionalInfo && (
        <>
          <br />
          <i>{additionalInfo}</i>
        </>
      )}
    </LevelLabel>
  );
};

export const ManyToManySelectInput = (props) => {
  const { input, source, label } = props;

  const renderLabel = (choice) => {
    const value = choice || input.value;
    switch (source) {
      case 'vehicles_ids':
        return `${value?.vehicle?.vin || ''} (${value?.vehicle?.plateNumber}, ${value?.vehicle?.brandType?.name || ''
          }, ${value?.vehicle?.modelType?.name || ''})`;
      case 'users':
        return `${value?.firstName || ''} ${value?.lastName || ''} (${value?.email
          })`;
      case 'sharedVehicleGroups':
        return value?.name || 'Clear';
    }
  };

  return (
    <FormControl sx={{ width: '50%' }}>
      <InputLabel>{label}</InputLabel>
      <ReferenceArrayInput
        source={props.source}
        label={label}
        reference="SharedVehicleGroupRelVehicle">
        <AutocompleteArrayInput optionText={renderLabel} />
      </ReferenceArrayInput>
    </FormControl>
  );
};

const DownloadPxlVision = ({ transactionCode, title }) => {
  return (
    (transactionCode && (
      <a
        target="_blank"
        href={`${url}/admin/pxl-vision-verification/${transactionCode}?access_token=${cookie.get(
          'access_token',
        )}`}
        download>
        {title}
      </a>
    )) ||
    null
  );
};

export const UserForm = (props) => {
  const { errors = {} } = props;
  const refresh = useRefresh();
  const notify = useNotify();
  const { setValue, setError } = useFormContext();
  const record = useRecordContext<UserType>();
  const { identity } = useGetIdentity();

  useEffect(() => {
    Object.keys(errors).forEach((propertyName) => {
      console.log(propertyName, errors[propertyName]);
      setError(propertyName, { type: 'custom', message: errors[propertyName] });
    });
  }, [errors]);

  const [update] = useUpdate();

  const [stripeOnboarding, { loading }] =
    useGenerateUserOnboardingStripeLinkMutation();
  const [resendEmailVerificationLink] =
    useResendEmailVerificationLinkMutation();

  const handleUpdateProperty = async (property: string, value: any) => {
    let data = {
      [property]: value,
    };

    if (property === 'pxlVisionIdAt') {
      data = set(data, 'pxlVisionIdManuallyVerifiedBy', identity.id as string);
    }
    if (property === 'pxlVisionDriverLicenseAt') {
      data = set(
        data,
        'pxlVisionDriverLicenseManuallyVerifiedBy',
        identity.id as string,
      );
    }

    await update('User', { id: record?.id, data }, { returnPromise: true });
    refresh();
  };

  const handleResetStripeLink = async () => {
    try {
      await stripeOnboarding({
        variables: { userId: record.id as string },
      });

      notify('E-mail was successfully sent', { type: 'success' });

      refresh();
    } catch (error) {
      notify(error.message, { type: 'error' });
    }
  };

  const handleResendEmailVerificationLink = async () => {
    try {
      await resendEmailVerificationLink({
        variables: { userId: record.id as string },
      });

      notify('E-mail was successfully sent', { type: 'success' });

      refresh();
    } catch (error) {
      notify(error.message, { type: 'error' });
    }
  };

  let _UserRole = UserRole;
  if (identity?.userRole === UserRole.CompanyAdmin) {
    _UserRole = omit(_UserRole, 'SuperAdmin') as any;
  }

  return (
    <TabPanel value={TABS.basicInfo}>
      <Grid container spacing={8}>
        <Grid item md={4}>
          <StyledBorder>
            <StyledTypography variant="h6" style={{ marginBottom: 24 }}>
              Basic info
            </StyledTypography>
            <TextInput
              source="firstName"
              error={errors?.firstName}
              style={{ width: '100%' }}
            />
            <TextInput
              source="middleName"
              error={errors?.middleName}
              style={{ width: '100%' }}
            />
            <TextInput
              source="lastName"
              error={errors?.lastName}
              style={{ width: '100%' }}
            />
            <TextInput
              source="email"
              validate={[required()]}
              error={errors?.email}
              style={{ width: '100%' }}
            />
            {identity?.userRole === UserRole.SuperAdmin && (
              <CompanyInput
                value={record?.companyId || undefined}
                source="companyId"
                onChange={(event) => setValue('company', event.target.value)}
              />
            )}
            {!record?.id && (
              <TextInput
                source="password"
                error={errors?.password}
                validate={[required()]}
                style={{ width: '100%' }}
              />
            )}

            <TextInput
              source="phone"
              error={errors?.phone}
              validate={[required()]}
              style={{ width: '100%' }}
            />
            <SelectInput
              source="language"
              error={errors?.language}
              choices={['DE', 'FR', 'IT', 'EN'].map((language) => ({
                id: language,
                name: language,
              }))}
              style={{ width: '100%' }}
            />
            {[UserRole.SuperAdmin, UserRole.CompanyAdmin].includes(identity?.userRole) && (
              <SelectInput
                source="userRole"
                fullWidth
                choices={convertEnumFilterItems(_UserRole)}
              />
            )}

            {record?.id && (
              <FlexRow style={{ marginTop: 12, flexWrap: 'wrap' }}>
                <FlexRow style={{ width: '100%' }}>
                  <LevelLabelItem
                    title={'Email verification'}
                    confirmed={record?.userLevel?.steps?.emailConfirmedAt}
                  />
                  <Button
                    variant="contained"
                    onClick={handleResendEmailVerificationLink}>
                    Resend
                  </Button>
                </FlexRow>
                <LevelLabelItem
                  title={'Phone verification'}
                  confirmed={record?.userLevel?.steps?.phoneConfirmedAt}
                />
                <FlexRow style={{ width: '100%' }}>
                  <LevelLabelItem
                    title={'ID verification'}
                    confirmed={record?.pxlVisionIdAt}
                    additionalInfo={
                      record?.pxlVisionIdAt &&
                      record?.pxlVisionIdManuallyVerifiedBy?.id && (
                        <div style={{ fontSize: 12 }}>
                          <u>
                            Verified by:{' '}
                            {record?.pxlVisionIdManuallyVerifiedBy?.email}
                          </u>
                        </div>
                      )
                    }
                  />
                  <Button
                    variant="contained"
                    style={{ marginRight: 6 }}
                    onClick={() =>
                      handleUpdateProperty('pxlVisionIdAt', {
                        set: null,
                      })
                    }>
                    Reset
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      handleUpdateProperty('pxlVisionIdAt', dayjs().toDate())
                    }>
                    Verify
                  </Button>
                </FlexRow>
                <FlexRow style={{ width: '100%' }}>
                  <LevelLabelItem
                    title={'Driver license verification'}
                    confirmed={record?.pxlVisionDriverLicenseAt}
                    additionalInfo={
                      record?.pxlVisionDriverLicenseAt &&
                      record?.pxlVisionDriverLicenseManuallyVerifiedBy?.id && (
                        <div style={{ fontSize: 12 }}>
                          <u>
                            Verified by:{' '}
                            {
                              record?.pxlVisionDriverLicenseManuallyVerifiedBy
                                ?.email
                            }
                          </u>
                        </div>
                      )
                    }
                  />
                  <Button
                    variant="contained"
                    style={{ marginRight: 6 }}
                    onClick={() =>
                      handleUpdateProperty('pxlVisionDriverLicenseAt', {
                        set: null,
                      })
                    }>
                    Reset
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      handleUpdateProperty(
                        'pxlVisionDriverLicenseAt',
                        dayjs().toDate(),
                      )
                    }>
                    Verify
                  </Button>
                </FlexRow>
                <LevelLabelItem
                  title={'Payment method verification'}
                  confirmed={record?.userLevel?.steps?.paymentMethod}
                  additionalInfo={
                    <div style={{ fontSize: 12 }}>
                      Twint:{' '}
                      {!!record.stripeTwintConfimedAt
                        ? dayjs(record.stripeTwintConfimedAt).format(
                          'DD.MM. YYYY HH:mm',
                        )
                        : '-'}
                      <br />
                      Cards:{' '}
                      {record?.paymentCards?.map((paymentCard) => `...${paymentCard.last4}`)?.join(', ')}
                    </div>
                  }
                />
                <LevelLabelItem
                  title={`User level: ${record?.userLevel?.level < 100
                    ? record?.userLevel?.level === 0
                      ? 0
                      : 1
                    : 2
                    }`}
                />
              </FlexRow>
            )}

            {record?.stripeAccountLink && (
              <>
                <a target="_blank" href={record?.stripeAccountLink}>
                  Stripe account onboarding
                </a>
                <br />
                <br />
              </>
            )}

            <DateTimeInput
              label="Stripe account connected at"
              variant="outlined"
              size="small"
              style={{ width: '100%' }}
              source="stripeAccountConfirmedAt"
            />

            <Button
              variant="contained"
              disabled={loading}
              startIcon={loading && <CircularProgress size="1em" />}
              onClick={handleResetStripeLink}>
              Resend stripe link
            </Button>
          </StyledBorder>
        </Grid>
        <Grid item md={4}>
          <StyledBorder>
            <StyledTypography variant="h6" style={{ marginBottom: 24 }}>
              Address info
            </StyledTypography>

            <SelectInput
              source="gender"
              variant="outlined"
              error={errors?.gender}
              choices={[GenderType.Male, GenderType.Female].map((language) => ({
                id: language,
                name: language,
              }))}
              style={{ width: '100%' }}
            />
            <DateInput
              source="birthdate"
              label="Birthdate"
              error={errors?.birthdate}
              style={{ width: '100%' }}
            />
            <SelectInput
              source="nationality"
              error={errors?.nationality}
              variant="outlined"
              size="small"
              choices={countriesList.map((country) => ({
                name: country.name,
                id: country.alpha2,
              }))}
              style={{ width: '100%' }}
            />
            {!['ch'].includes(record?.nationality || '') && (
              <SelectInput
                source="residencePermit"
                error={errors?.residencePermit}
                variant="outlined"
                size="small"
                choices={[
                  {
                    name: 'Settlement permit',
                    id: '1',
                  },
                  {
                    name: 'Season permit',
                    id: '2',
                  },
                  {
                    name: 'Annual ppermit',
                    id: '3',
                  },
                  {
                    name: 'Asylum seekers',
                    id: '4',
                  },
                  {
                    name: 'Special permit',
                    id: '5',
                  },
                  {
                    name: 'Special regulation',
                    id: '6',
                  },
                  {
                    name: 'Diplomats',
                    id: '7',
                  },
                  {
                    name: 'Cross-border commuter permit',
                    id: '8',
                  },
                  {
                    name: 'Short stay permit',
                    id: '9',
                  },
                  {
                    name: 'Preliminary recording',
                    id: '10',
                  },
                  {
                    name: 'No permit',
                    id: '11',
                  },
                ]}
                style={{ width: '100%' }}
              />
            )}
            <TextInput
              source="address"
              error={errors?.address}
              style={{ width: '100%' }}
            />
            <TextInput
              source="houseNumber"
              error={errors?.houseNumber}
              style={{ width: '100%' }}
            />
            <TextInput
              source="city"
              error={errors?.city}
              style={{ width: '100%' }}
            />
            <TextInput
              source="postCode"
              error={errors?.postCode}
              style={{ width: '100%' }}
            />

            {record?.pxlVisionIdCode && (
              <DownloadPxlVision
                transactionCode={record.pxlVisionIdCode}
                title="Download ID pxl vision"
              />
            )}
          </StyledBorder>

          <StyledBorder style={{ marginTop: 30 }}>
            <StyledTypography variant="h6" style={{ marginBottom: 24 }}>
              Commission
            </StyledTypography>

            <Row>
              <NumberInput
                label="Commission for bookings (%)"
                source="commissionBookings"
                validate={[minValue(0), maxValue(100)]}
              />
            </Row>
            <Row>
              <NumberInput
                label="Commission for charging stations (%)"
                source="commissionChargingStations"
                validate={[minValue(0), maxValue(100)]}
              />
            </Row>
            <Row>
              <NumberInput
                label="Commission for scooters (%)"
                source="commissionScooters"
                validate={[minValue(0), maxValue(100)]}
              />
            </Row>
          </StyledBorder>
          <StyledBorder style={{ marginTop: 30 }}>
            <StyledTypography variant="h6" style={{ marginBottom: 24 }}>
              Notifications
            </StyledTypography>

            <Row>
              <BooleanInput label="Sms" source="notificationsIsEnabledSms" />
            </Row>
            <Row>
              <BooleanInput
                label="Email"
                source="notificationsIsEnabledEmail"
              />
            </Row>
            <Row>
              <BooleanInput
                label="Push notifications"
                source="notificationsIsEnabledPush"
              />
            </Row>
          </StyledBorder>
        </Grid>
        <Grid item md={4}>
          <StyledBorder>
            <StyledTypography variant="h6" style={{ marginBottom: 24 }}>
              Driver licence
            </StyledTypography>

            <TextInput
              source="driverFirstName"
              error={errors?.driverFirstName}
              style={{ width: '100%' }}
            />
            <TextInput
              source="driverMiddleName"
              error={errors?.driverMiddleName}
              style={{ width: '100%' }}
            />
            <TextInput
              source="driverLastName"
              error={errors?.driverLastName}
              style={{ width: '100%' }}
            />
            <TextInput
              source="driverLicenceNumber"
              error={errors?.driverLicenceNumber}
              style={{ width: '100%' }}
            />
            <DateInput
              source="driverExpirationAt"
              label="License expiration date"
              error={errors?.driverExpirationAt}
              style={{ width: '100%' }}
            />
            <DateInput
              source="driverDateOfIssueAt"
              label="License date of issue"
              error={errors?.driverDateOfIssueAt}
              style={{ width: '100%' }}
            />
            <DateInput
              source="driverBirthdate"
              label="Driver Birthdate"
              error={errors?.driverBirthdate}
              style={{ width: '100%' }}
            />
            {record?.pxlVisionDriverLicenseCode && (
              <DownloadPxlVision
                transactionCode={record.pxlVisionDriverLicenseCode}
                title="Download Driver license pxl vision"
              />
            )}
          </StyledBorder>
        </Grid>
      </Grid>
    </TabPanel>
  );
};

export const UserAside = () => {
  const record = useRecordContext<User>();
  const { data, isLoading } = useGetList('Vehicle', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'createdAt', order: 'DESC' },
    filter: {
      userId: record?.id || 'created',
    },
  });

  return (
    <Card
      variant="outlined"
      style={{
        minWidth: 300,
        margin: '0 1em 0',
        float: 'right',
        height: '90vh',
        position: 'sticky',
        top: 30,
      }}>
      <CardContent style={{ height: '50vh' }}>
        <Typography>Assigned vehicles</Typography>

        {(isLoading && <LoadingElement />) || (
          <List
            sx={{
              overflow: 'auto',
              height: '35vh',
              maxWidth: 360,
              bgcolor: 'background.paper',
            }}>
            {Object.values(data).map((vehicle: Vehicle) => (
              <ListItemButton
                key={vehicle.id}
                alignItems="flex-start"
                href={`#/Vehicle/${vehicle.id}`}
                target="_blank"
                component="a">
                <ListItemAvatar>
                  <Avatar
                    alt="Vehicle image"
                    src={getStoragePath(
                      `gallery/${vehicle?.defaultVehicleImage}`,
                    )}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={vehicle.vin}
                  secondary={vehicle.disabled ? 'Unpublished' : 'Published'}
                />
              </ListItemButton>
            ))}
          </List>
        )}
      </CardContent>
      <CardContent style={{ height: '50vh' }}>
        <Typography>Assigned SVG's</Typography>

        {(isLoading && <LoadingElement />) || (
          <AssignedSharedVehicleGroupList
            sharedVehicleGroupRels={uniqBy(
              [
                ...(record?.sharedVehicleGroupRelUser || []),
                ...(record?.company?.sharedVehicleGroupRelCompany || []),
                ...(record?.company?.defaultSharedVehicleGroup
                  ? [
                    {
                      id: '',
                      sharedVehicleGroup:
                        record?.company?.defaultSharedVehicleGroup,
                    },
                  ]
                  : []),
              ],
              'sharedVehicleGroup.name',
            ).filter(item => item.sharedVehicleGroup.deletedAt === null)}
          />
        )}
      </CardContent>
    </Card>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  & > div {
    flex: 1;
  }
  div ~ div {
    margin-left: 10px;
  }
`;
