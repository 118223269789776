import {
  AutocompleteArrayInput,
  BooleanInput,
  NumberInput,
  TextInput,
  useGetIdentity,
  useRecordContext,
} from 'react-admin';

import { ReferenceManyToManyInput } from '@react-admin/ra-relationships';
import { UserRole } from '../../@generated/schemas';

export const renderLabel = (choice, type: string) => {
  if (type === 'users') {
    return `${choice.firstName || ''} ${choice.lastName || ''} (${choice.email
      })`;
  }

  if (type === 'companies') {
    return choice.name;
  }

  return `${choice?.vin || ''} (${choice?.plateNumber || 'Unknown plate number'
    }, ${choice?.brandType?.name || 'Unknown brand type'}, ${choice?.modelType?.name || 'Unknown model type'
    })`;
};

export const getTransformedData = (data) => {
  data.vehicles =
    data[
    '@@ra-many-to-many/SharedVehicleGroup/SharedVehicleGroupRelVehicle/Vehicle'
    ] || [];
  data.users =
    data[
    '@@ra-many-to-many/SharedVehicleGroup/SharedVehicleGroupRelUser/User'
    ] || [];
  data.companies =
    data[
    '@@ra-many-to-many/SharedVehicleGroup/SharedVehicleGroupRelCompany/Company'
    ] || [];

  if (data.discount > 100) {
    data.discount = 100;
  }
  if (data.discount < 0) {
    data.discount = 0;
  }

  return data;
};

export const SharedVehicleGroupForm = () => {
  const record = useRecordContext();
  const { identity } = useGetIdentity();

  return (
    <>
      <TextInput source="name" style={{ width: '20vw' }} />
      <NumberInput
        source="discount"
        max={100}
        min={0}
        style={{ width: '20vw' }}
      />
      <BooleanInput source="ownerBehavior" />
      <BooleanInput source="isPrivate" />
      <BooleanInput source="discountIncludesInsurance" />

      <ReferenceManyToManyInput
        resource="SharedVehicleGroup"
        reference="Vehicle"
        through="SharedVehicleGroupRelVehicle"
        source="id"
        record={{ id: record?.id }}
        using="sharedVehicleGroupId,vehicleId">
        <AutocompleteArrayInput
          label="Vehicles"
          filterOptions={(items: any[]) => {
            console.log(items.filter(item => item.deletedAt === null));
            return items.filter(item => item.deletedAt === null);
          }}
          style={{ width: '40vw' }}
          optionText={(choice) => renderLabel(choice, 'vehicles')}
        />
      </ReferenceManyToManyInput>

      <br />
      <br />

      <ReferenceManyToManyInput
        resource="SharedVehicleGroup"
        reference="User"
        through="SharedVehicleGroupRelUser"
        source="id"
        record={{ id: record?.id }}
        using="sharedVehicleGroupId,userId">
        <AutocompleteArrayInput
          label="Users"
          style={{ width: '40vw' }}
          optionText={(choice) => renderLabel(choice, 'users')}
        />
      </ReferenceManyToManyInput>

      <br />
      <br />

      {identity?.userRole && identity?.userRole === UserRole.SuperAdmin && (
        <ReferenceManyToManyInput
          resource="SharedVehicleGroup"
          reference="Company"
          through="SharedVehicleGroupRelCompany"
          source="id"
          filter={{
            deletedAt: { equals: null },
          }}
          record={{ id: record?.id }}
          using="sharedVehicleGroupId,companyId">
          <AutocompleteArrayInput
            label="Companies"
            style={{ width: '40vw' }}
            optionText={(choice) => renderLabel(choice, 'companies')}
          />
        </ReferenceManyToManyInput>
      )}
    </>
  );
};
