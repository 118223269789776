import { ResourceView } from '@ra-data-prisma/dataprovider';
import { gql } from '@apollo/client';

const fragmentBookingRequest = gql`
  fragment BookingRequestFragment on BookingRequest {
    user {
      id
      firstName
      lastName
      email
    }
    createdBy {
      id
      firstName
      lastName
      email
    }
  }
`;

export const BookingRequest: ResourceView = {
  resource: 'BookingRequest',
  fragment: {
    one: {
      type: 'document',
      mode: 'extend',
      doc: fragmentBookingRequest,
    },
    many: {
      type: 'document',
      mode: 'extend',
      doc: fragmentBookingRequest,
    },
  },
};
