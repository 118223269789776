import { FC, useCallback } from 'react';
import { PlacedTiles, TilesProps } from './types';

import { Tile } from '..';

const Tiles: FC<TilesProps> = ({ data, zoom, onTileClick, onItemResize, onFocusTile }) => {
  const placeTiles = useCallback((): PlacedTiles => {
    let rows = 0;
    return data
      .map((person, personIndex) => {
        if (personIndex > 0) {
          rows += Math.max(data[personIndex - 1].data.length, 1);
        }
        return person.data.map((projectsPerRow, rowIndex) =>
          projectsPerRow.map((project) => (
            <Tile
              key={project.id}
              row={rowIndex + rows}
              data={project}
              zoom={zoom}
              onItemResize={onItemResize}
              onFocusTile={onFocusTile}
              onTileClick={onTileClick}
            />
          )),
        );
      })
      .flat(2);
  }, [data, onTileClick, zoom]);

  return <>{placeTiles()}</>;
};

export default Tiles;
