import { FC, useCallback, useState } from 'react';

import { DropZoneProps } from './types';
import { StyledDropZone } from './styles';
import { getFocusedDate } from '../../../../utils/getFocusedDate';
import { getStartDate } from '../../../../utils/getStartDate';
import { useCalendar } from '../../../../context/CalendarProvider';

const DropZone: FC<DropZoneProps> = ({
  topPosition,
  zoom,
  height,
  vehicleId,
  onItemDrop,
  onItemCreate,
}) => {
  const { startDate } = useCalendar();
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  const onDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      const item = JSON.parse(event.dataTransfer.getData('application/json'));
      const dropZoneRect = event.currentTarget.getBoundingClientRect();
      const position = event.clientX - dropZoneRect.left;

      const date = getFocusedDate(
        startDate,
        position,
        zoom,
        item.fromStart,
        item.fromEnd,
      );

      onItemDrop(item, {
        id: item.id,
        start: date.start,
        end: date.end,
      });

      setIsDraggedOver(false);
    },
    [zoom, startDate, onItemDrop],
  );

  const onClick = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      const dropZoneRect = event.currentTarget.getBoundingClientRect();
      const position = event.clientX - dropZoneRect.left;

      const newCreatedDate = getStartDate({
        currentDate: startDate,
        position,
        zoom,
      });
      console.log('newCreatedDate', newCreatedDate);
      onItemCreate(newCreatedDate, vehicleId);
    },
    [zoom, startDate, onItemDrop],
  );

  const handleDragEnter = () => setIsDraggedOver(true);
  const handleDragLeave = () => setIsDraggedOver(false);
  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => e.preventDefault();

  return (
    <StyledDropZone
      isDraggedOver={true}
      height={height}
      topPosition={topPosition}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onClick={onClick}
    />
  );
};

export default DropZone;
