import { StyledCanvas, StyledInnerWrapper, StyledWrapper } from './styles';
import {
  boxHeight,
  canvasWrapperId,
  leftColumnWidth,
  outsideWrapperId,
} from '../../../constants';
import { forwardRef, useCallback, useEffect, useRef } from 'react';

import { DropZones } from '../DropZones';
import { GridProps } from './types';
import { Tiles } from '../../../components';
import { drawGrid } from '../../../utils/drawGrid/drawGrid';
import { getCanvasWidth } from '../../../utils/getCanvasWidth';
import { resizeCanvas } from '../../../utils/resizeCanvas';
import { useCalendar } from '../../../context/CalendarProvider';
import { useTheme } from 'styled-components';

const Grid = forwardRef<HTMLDivElement, GridProps>(function Grid(
  {
    zoom,
    rows,
    data,
    onTileClick,
    onFocusTile,
    onItemCreate,
    rowsPerItem,
    onItemResize,
    onItemDrop,
  },
  ref,
) {
  const { handleScrollNext, handleScrollPrev, date, cols, startDate } =
    useCalendar();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const refRight = useRef<HTMLSpanElement>(null);
  const refLeft = useRef<HTMLSpanElement>(null);

  const theme = useTheme();

  const handleResize = useCallback(
    (ctx: CanvasRenderingContext2D) => {
      const width = getCanvasWidth();
      const height = rows * boxHeight + 1;
      resizeCanvas(ctx, width, height);
      drawGrid(ctx, zoom, rows, cols, startDate, theme);
    },
    [cols, startDate, rows, zoom, theme],
  );

  useEffect(() => {
    if (!canvasRef.current) return;
    const ctx = canvasRef.current.getContext('2d');
    if (!ctx) return;

    const onResize = () => handleResize(ctx);

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [handleResize]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    canvas.style.letterSpacing = '1px';
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    handleResize(ctx);
  }, [date, rows, zoom, handleResize]);

  useEffect(() => {
    if (!refRight.current) return;
    const observerRight = new IntersectionObserver(
      (e) => (e[0].isIntersecting ? handleScrollNext() : null),
      { root: document.getElementById(outsideWrapperId) },
    );
    observerRight.observe(refRight.current);

    return () => observerRight.disconnect();
  }, [handleScrollNext]);

  useEffect(() => {
    if (!refLeft.current) return;
    const observerLeft = new IntersectionObserver(
      (e) => (e[0].isIntersecting ? handleScrollPrev() : null),
      {
        root: document.getElementById(outsideWrapperId),
        rootMargin: `0px 0px 0px -${leftColumnWidth}px`,
      },
    );
    observerLeft.observe(refLeft.current);

    return () => observerLeft.disconnect();
  }, [handleScrollPrev]);

  return (
    <StyledWrapper id={canvasWrapperId} className="container">
      <StyledInnerWrapper ref={ref}>
        <Tiles
          data={data}
          zoom={zoom}
          onItemResize={onItemResize}
          onFocusTile={onFocusTile}
          onTileClick={onTileClick}
        />
        <StyledCanvas ref={canvasRef} />
        <DropZones
          data={data}
          rowsPerItem={rowsPerItem}
          onItemDrop={onItemDrop}
          onItemCreate={onItemCreate}
          zoom={zoom}
        />
      </StyledInnerWrapper>
    </StyledWrapper>
  );
});

export default Grid;
