import {
  DELETE,
  DELETE_MANY,
  GET_LIST,
  GET_MANY,
  LegacyDataProvider,
  UPDATE,
  UPDATE_MANY,
  sanitizeFetchType,
} from 'react-admin';
import { Options, OurOptions } from '@ra-data-prisma/dataprovider/lib/types';

import { buildQueryFactory } from '@ra-data-prisma/dataprovider/lib/buildQuery';
import buildRaGraphqlDataProvider from 'ra-data-graphql';
import { makeIntrospectionOptions } from '@ra-data-prisma/dataprovider/lib/utils/makeIntrospectionOptions';
import merge from 'lodash/merge';

export const defaultOurOptions: OurOptions = {
  queryDialect: 'nexus-prisma',
};

export const defaultOptions: Options = {
  clientOptions: { uri: '/graphql' },
  ...defaultOurOptions,
};

const buildDataProvider = (options: Options): Promise<LegacyDataProvider> => {
  const fullOptions = merge({}, defaultOptions, options);
  return buildRaGraphqlDataProvider(
    merge(
      {},
      {
        buildQuery: (introspectionResults) =>
          buildQueryFactory(introspectionResults, fullOptions),
        introspection: makeIntrospectionOptions(fullOptions),
      },
      fullOptions,
    ),
  ).then((graphQLDataProvider) => {
    return (
      fetchType: string,
      resource: string,
      params: { [key: string]: any },
    ): Promise<any> => {
      if (fetchType === GET_LIST && resource === 'User') {
        params.filter.deletedAt = {
          equals: null
        };
        if (params.filter.userLevel === 0) {
          // params.filter.gender = { equals: null };
          // params.filter.firstName = { equals: null };
          // params.filter.lastName = { equals: null };
          // params.filter.birthdate = { equals: null };
          // params.filter.address = { equals: null };
          // params.filter.postCode = { equals: null };
          // params.filter.city = { equals: null };
          // params.filter.nationality = { equals: null };
          params.filter.OR = [
            { emailConfirmedAt: { equals: null } },
            { phoneConfirmedAt: { equals: null } },
            { gender: { equals: null } },
            { firstName: { equals: null } },
            { lastName: { equals: null } },
            { birthdate: { equals: null } },
            { address: { equals: null } },
            { postCode: { equals: null } },
            { city: { equals: null } },
            { nationality: { equals: null } },
            { pxlVisionDriverLicenseAt: { equals: null } },
            { pxlVisionIdAt: { equals: null } },
          ];
          // params.filter.pxlVisionDriverLicenseAt = { equals: null };
          // params.filter.pxlVisionIdAt = { equals: null };
          params.filter.stripeTwintConfimedAt = { equals: null };
          params.filter.stripeConfirmed = { equals: null };
          params.filter.stripeClientSecret = { equals: null };
          params.filter.stripeCustomerId = { equals: null };

          delete params.filter.userLevel;
        } else if (params.filter.userLevel === 2) {
          params.filter.gender = { not: { equals: null } };
          params.filter.firstName = { not: { equals: null } };
          params.filter.lastName = { not: { equals: null } };
          params.filter.birthdate = { not: { equals: null } };
          params.filter.address = { not: { equals: null } };
          params.filter.postCode = { not: { equals: null } };
          params.filter.city = { not: { equals: null } };
          params.filter.nationality = { not: { equals: null } };
          params.filter.emailConfirmedAt = { not: { equals: null } };
          params.filter.phoneConfirmedAt = { not: { equals: null } };
          params.filter.pxlVisionDriverLicenseAt = { not: { equals: null } };
          params.filter.pxlVisionIdAt = { not: { equals: null } };
          params.filter.OR = [
            { stripeTwintConfimedAt: { not: { equals: null } } },
            {
              stripeConfirmed: { equals: true },
              stripeClientSecret: { not: { equals: null } },
              stripeCustomerId: { not: { equals: null } },
            },
          ];

          delete params.filter.userLevel;
        } else if (params.filter.userLevel === 1) {
          params.filter.gender = { not: { equals: null } };
          params.filter.firstName = { not: { equals: null } };
          params.filter.lastName = { not: { equals: null } };
          params.filter.birthdate = { not: { equals: null } };
          params.filter.address = { not: { equals: null } };
          params.filter.postCode = { not: { equals: null } };
          params.filter.city = { not: { equals: null } };
          params.filter.nationality = { not: { equals: null } };
          params.filter.emailConfirmedAt = { not: { equals: null } };
          params.filter.phoneConfirmedAt = { not: { equals: null } };
          params.filter.pxlVisionDriverLicenseAt = { not: { equals: null } };
          params.filter.pxlVisionIdAt = { not: { equals: null } };
          params.filter.stripeTwintConfimedAt = { equals: null };
          params.filter.OR = [
            { stripeTwintConfimedAt: { equals: null } },
            { stripeConfirmed: { not: { equals: true } } },
          ];

          delete params.filter.userLevel;
        }
      }

      // Temporary work-around until we make use of updateMany and deleteMany mutations
      if (fetchType === DELETE_MANY) {
        const { ids, ...otherParams } = params;
        return Promise.all(
          params.ids.map((id: string) =>
            graphQLDataProvider[sanitizeFetchType(DELETE)](resource, {
              id,
              ...otherParams,
            }),
          ),
        ).then((results) => {
          return { data: results.map(({ data }: any) => data.id) };
        });
      }

      if (fetchType === UPDATE_MANY) {
        const { ids, ...otherParams } = params;
        return Promise.all(
          params.ids.map((id: string) =>
            graphQLDataProvider[sanitizeFetchType(UPDATE)](resource, {
              id,
              ...otherParams,
            }),
          ),
        ).then((results) => {
          return { data: results.map(({ data }: any) => data.id) };
        });
      }

      if (fetchType === GET_LIST && resource === 'Booking') {
        if (params?.filter?.cancelDate === true) {
          delete params.filter.cancelDate;
        }
      }

      return graphQLDataProvider[sanitizeFetchType(fetchType)](
        resource,
        params,
      );
    };
  });
};

export default buildDataProvider;
