import { ResourceView } from '@ra-data-prisma/dataprovider';
import { gql } from '@apollo/client';

const fragmentUser = gql`
  fragment UserFragment on User {
    company {
      id
      name
      sharedVehicleGroupRelCompany {
        sharedVehicleGroup {
          id
          name
          discount
          deletedAt
          ownerBehavior
          isPrivate
        }
      }
      defaultSharedVehicleGroup {
        id
        name
        discount
        deletedAt
        ownerBehavior
        isPrivate
      }
    }
    sharedVehicleGroupRelUser {
      sharedVehicleGroup {
        id
        name
        discount
        deletedAt
        ownerBehavior
        isPrivate
      }
    }
    paymentCards {
      id
      brand
      last4
    }
    userLevel {
      level
      steps {
        emailConfirmedAt
        phoneConfirmedAt
        identificationDocumentVerified
        drivingLicenseVerified
        paymentMethod
      }
    }
    pxlVisionIdManuallyVerifiedBy {
      id
      email
    }
    pxlVisionDriverLicenseManuallyVerifiedBy {
      id
      email
    }
    pxlVisionManualVerifications {
      id
      type
      verifiedAt
      verifiedByUser {
        id
        email
      }
      createdAt
    }
  }
`;

export const User: ResourceView = {
  resource: 'User',
  fragment: {
    one: {
      type: 'document',
      mode: 'extend',
      doc: fragmentUser,
    },
    many: {
      type: 'document',
      mode: 'replace',
      doc: gql`
        fragment UserFragment on User {
          id
          address
          isStripeOnboarded
          phone
          preDeletedAt
          createdAt
          phoneConfirmedAt
          postCode
          language
          userLevel {
            level
          }
          userRole
          lastName
          middleName
          loginBlockedAt
          birthdate
          driverBirthdate
          driverCountry
          driverDateOfIssueAt
          driverExpirationAt
          driverFirstName
          driverLastName
          driverLicenceNumber
          driverMiddleName
          email
          emailConfirmedAt
          failedLoginAttempts
          firstName
          houseNumber
          company {
            id
            name
          }
        }
      `,
    },
  },
};
