import { Day, ZoomLevel } from "../types/global";
import { dayWidth, weekWidth, zoom2ColumnWidth } from "../constants";

import dayjs from "dayjs";

export type GetStartDateProps = {
  currentDate: Day;
  position: number;
  zoom: ZoomLevel;
};

export const getStartDate = ({ currentDate, position, zoom }: GetStartDateProps) => {
  let timeUnit: dayjs.ManipulateType = "weeks";
  let currBoxWidth;

  switch (zoom) {
    case 0:
      timeUnit = "weeks";
      currBoxWidth = weekWidth;
      break;
    case 1:
      timeUnit = "days";
      currBoxWidth = dayWidth;
      break;
    case 2:
      timeUnit = "hours";
      currBoxWidth = zoom2ColumnWidth;
      break;
  }

  const column =
    zoom === 2
      ? Math.ceil((position - 0.5 * currBoxWidth) / currBoxWidth)
      : Math.ceil(position / currBoxWidth);

  const startDay = dayjs(
    `${currentDate.year}-${currentDate.month + 1}-${currentDate.dayOfMonth}T${currentDate.hour}:00:00`
  ).add(column - 1, timeUnit);

  console.log('startDay', startDay);

  return startDay.toDate();
};