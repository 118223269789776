import {
  Filters,
  NavBtn,
  NavigationWrapper,
  OptionsContainer,
  Today,
  Wrapper,
  Zoom,
} from './styles';

import { Button } from '@mui/material';
import { FC } from 'react';
import { Icon } from '../../../../components';
import { TopbarProps } from './types';
import { useCalendar } from '../../../../context/CalendarProvider';
import { useLanguage } from '../../../../context/LocaleProvider';
import { useTheme } from 'styled-components';

const Topbar: FC<TopbarProps> = ({ width }) => {
  const { topbar } = useLanguage();
  const { data, handleGoNext, handleGoPrev, handleGoToday, zoom, onClickNewRequest, changeZoom } =
    useCalendar();
  const { colors } = useTheme();

  return (
    <Wrapper width={width}>
      <Filters></Filters>
      <NavigationWrapper>
        <NavBtn disabled={!data?.length} onClick={handleGoPrev}>
          <Icon iconName="arrowLeft" height="15" fill={colors.textPrimary} />
          {topbar.prev}
        </NavBtn>
        <Today onClick={handleGoToday}>{topbar.today}</Today>
        <NavBtn disabled={!data?.length} onClick={handleGoNext}>
          {topbar.next}
          <Icon iconName="arrowRight" height="15" fill={colors.textPrimary} />
        </NavBtn>
      </NavigationWrapper>
      <OptionsContainer>
        <Button variant="contained" onClick={onClickNewRequest}>New request</Button>
        <Zoom>
          <Button
            onClick={() => changeZoom(0)}
            variant={zoom === 0 ? 'contained' : 'outlined'}
            size="small">
            Monthly
          </Button>
          <Button
            onClick={() => changeZoom(1)}
            variant={zoom === 1 ? 'contained' : 'outlined'}
            size="small">
            Weekly
          </Button>
          <Button
            onClick={() => changeZoom(2)}
            variant={zoom === 2 ? 'contained' : 'outlined'}
            size="small">
            Daily
          </Button>
        </Zoom>
      </OptionsContainer>
    </Wrapper>
  );
};
export default Topbar;
