import { boxHeight } from '../../../../constants';
import styled from 'styled-components';

export const StyledDropZone = styled.div<{
  topPosition: number;
  isDraggedOver: boolean;
  height: number;
}>`
  position: absolute;
  z-index: 8;
  width: 100%;
  height: ${({ height }) => height * boxHeight}px;
  opacity: ${({ isDraggedOver }) => (isDraggedOver ? 0.2 : 0)};
  top: ${({ topPosition }) => topPosition * boxHeight}px;
  height: ${boxHeight}px;
  transition: opacity 0.2s ease, background 0.2s ease;
`;
