import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  Create,
  Datagrid,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  Filter,
  List,
  NumberInput,
  ReferenceInput,
  SimpleForm,
  TextField,
  TextInput,
  useGetIdentity,
  useRecordContext,
} from 'react-admin';

import CustomPagination from '../../CustomPagination';
import { Grid } from '@mui/material';
import { ReferenceManyToManyInput } from '@react-admin/ra-relationships';
import { UserRole } from '../../@generated/schemas';
import { renderLabel } from '../sharedVehicleGroup/form';
import styled from 'styled-components';

const Form = () => {
  const record = useRecordContext();
  const { identity } = useGetIdentity();

  return (
    <Grid container spacing={8}>
      <Grid item md={5}>
        <TextInput source="name" fullWidth />
        {identity?.userRole && identity?.userRole === UserRole.SuperAdmin && (
          <ReferenceInput
            source="company_id"
            reference="Company"
            filter={{
              deletedAt: { equals: null },
            }}>
            <AutocompleteInput fullWidth optionText="name" />
          </ReferenceInput>
        )}

        <ReferenceManyToManyInput
          resource="CompanyVehicleGroup"
          reference="Vehicle"
          through="CompanyVehicleGroupRelVehicle"
          source="id"
          record={{ id: record?.id }}
          using="companyVehicleGroupId,vehicleId">
          <AutocompleteArrayInput
            label="Vehicles"
            optionText={(choice) => renderLabel(choice, 'vehicles')}
          />
        </ReferenceManyToManyInput>
      </Grid>
      <Grid item md={5}>
        <BooleanInput source="enabledDamages" label="Enable damages" />
        <BooleanInput
          source="enabledBeforeQuestions"
          label="Enable before questions"
        />
        <BooleanInput
          source="enabledAfterQuestions"
          label="Enable after questions"
        />
        <BooleanInput
          source="enabledCancellationFees"
          label="Enable cancellation"
        />
        <BooleanInput
          source="enabledAutoconfirmation"
          label="Enable auto confirmation"
        />
        <BooleanInput
          source="enabledExtendingBooking"
          label="Enable extending booking time"
        />
        <BooleanInput
          source="enabledCheckVehicleStatus"
          label="Enable check vehicle status"
        />
        <BooleanInput
          source="enabledCreateBookingFromApp"
          label="Enable create booking from app"
        />
        <BooleanInput
          source="enabledCheckUserLevel"
          label="Enable check user level"
        />
        <BooleanInput
          source="enabledBookingScheduler"
          label="Enable booking scheduler"
        />
        <BooleanInput
          source="enabledVehicleCurrentPosition"
          label="Enable vehicle current position"
        />
        <BooleanInput
          source="enabledRequestVehicle"
          label="Enable request vehicle"
        />
        <NumberInput
          source="minFuelLevel"
          min={20}
          fullWidth
          label="Minimum fuel level"
        />
        <NumberInput
          source="minBatteryLevel"
          min={20}
          fullWidth
          label="Minimum batery level"
        />
      </Grid>
    </Grid>
  );
};

export const getTransformedData = (data) => {
  console.log(data);
  data.companyVehicleGroupRelVehicles =
    data[
      '@@ra-many-to-many/CompanyVehicleGroup/CompanyVehicleGroupRelVehicle/Vehicle'
    ] || [];

  if (data.minFuelLevel > 100) {
    data.minFuelLevel = 100;
  }
  if (data.minFuelLevel < 20) {
    data.minFuelLevel = 20;
  }
  if (data.minBatteryLevel > 100) {
    data.minBatteryLevel = 100;
  }
  if (data.minBatteryLevel < 20) {
    data.minBatteryLevel = 20;
  }

  return data;
};

export const CompanyVehicleGroupCreate = (props: any) => {
  const { identity } = useGetIdentity();
  return (
    <Create
      {...props}
      mutationMode="pessimistic"
      transform={getTransformedData}>
      <SimpleForm
        defaultValues={{
          enabledDamages: true,
          enabledBeforeQuestions: true,
          enabledAfterQuestions: true,
          enabledCancellationFees: true,
          enabledAutoconfirmation: true,
          enabledCheckVehicleStatus: true,
          enabledCreateBookingFromApp: true,
          enabledCheckUserLevel: true,
          enabledBookingScheduler: false,
          enabledRequestVehicle: false,
          enabledVehicleCurrentPosition: false,
          enabledExtendingBooking: true,
          minFuelLevel: 20,
          minBatteryLevel: 20,
          '@@ra-many-to-many/CompanyVehicleGroup/CompanyVehicleGroupRelVehicle/Vehicle':
            identity?.userRole && identity?.userRole !== UserRole.SuperAdmin
              ? [identity.companyId]
              : [],
        }}>
        <Form {...props} />
      </SimpleForm>
    </Create>
  );
};

export const CompanyVehicleGroupEdit = (props: any) => (
  <Edit
    {...props}
    redirect={false}
    mutationMode="pessimistic"
    transform={getTransformedData}>
    <SimpleForm>
      <Form {...props} />
    </SimpleForm>
  </Edit>
);

export const CompanyVehicleGroupFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const CompanyVehicleGroupList = (props: any) => {
  return (
    <List
      {...props}
      filters={<CompanyVehicleGroupFilter />}
      pagination={<CustomPagination />}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="name" />
        <EditButton />
        <DeleteWithConfirmButton mutationMode="pessimistic" />
      </Datagrid>
    </List>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  & > div {
    flex: 1;
  }
  div ~ div {
    margin-left: 10px;
  }
`;

const LogoWrapper = styled.div`
  background-color: #f0f0f0;
  max-height: 100px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
