import { FC, useMemo } from "react";

import { DropZone } from "./DropZone";
import { DropZonesProps } from "./types";

const DropZones: FC<DropZonesProps> = ({ data, zoom, rowsPerItem, onItemDrop, onItemCreate }) => {
  const placeDropZones = useMemo(() => {
    let rows = 0;
    return data.map((item, index) => {
      if (index > 0) rows += rowsPerItem[index - 1];
      const pointer = 0;
      const topPosition = pointer + rows;
      const height = data.length;
      return (
        <DropZone
          key={`${item.id}-${rows}`}
          vehicleId={item.id}
          height={height}
          zoom={zoom}
          onItemCreate={onItemCreate}
          topPosition={topPosition}
          onItemDrop={onItemDrop}
        />
      );
    });
  }, [data, rowsPerItem]);
  return <>{placeDropZones}</>;
};
export default DropZones;